const state = {
 currentUser : null,
 loadingCurrentUser: true
}
const getters = {

}
const mutations = {
  setCurrentUser (state, user) {
    // console.log('Set current user')
    // console.log(user)
    state.currentUser = user
  },
  setCurrentUserLoading (state, loading) {
    // console.log('Setting loading flag')
    // console.log(loading)
    state.loadingCurrentUser = loading
  }
}
const actions = {
  //Fetch from server if it doesn't already exist
  async fetchCurrentUserData(context, reload) {
    if (reload||context.state.currentUser==null)
    {
      context.commit('setCurrentUserLoading',true)
      return new Promise((resolve, reject) => {
        context.dispatch('auth/getCurrentUserData',null,{root:true})
          .then(response => {
            //  console.log(response)
            context.commit('setCurrentUser',response)
            context.commit('setCurrentUserLoading',false)
            resolve(response)
          }).catch(err => {
            console.log('could not get current user data')
            console.dir(err)
            context.commit('setCurrentUser',null)
            context.commit('setCurrentUserLoading',false)
            reject(err)
          })
      })

  }
 }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
