<template>
  <div class="home">
    <b-container>
      <b-row class="my-4">
        <b-col cols="12" md="7" lg="8">
          <b-card title="Welcome to the SafearthID Portal."
                  border-variant="white">
              <div>
                <p>You can create a new SafearthID or manage your account here.</p>
                
                <p>You will use your SafearthID to access our Store, Support, Resources and Software.</p>
                
                <b-button @click="login" variant="outline-primary" class="mr-3">Log in</b-button>
                <b-button to="sign-up" variant="outline-primary">Sign up</b-button>
              </div>
           
          </b-card>
        </b-col>
        <b-col cols="12" md="5" lg="4">
          <b-card header="Help" 
                  border-variant="secondary"
                  header-class="font-weight-bold text-center h5"
                  header-border-variant="secondary"
                  header-text-variant="black"
                  >
            <ul>
              <li>
                <b-link to="/reset-password">Forgot my password</b-link>
              </li>
              <li>
                <b-link to="/resend-email">Resend verification email</b-link >
                </li>
                <li>
                  Contact Us: 
                  <b-link href="mailto:support@safearth.com">
                    support@safearth.com
                  </b-link>
                </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data: function () {
    return {
      'PRODUCT_NAME': process.env.VUE_APP_PRODUCT_NAME
    }
  },
  computed: {
    isLoggedIn: {
      get () {
        return this.$store.getters['auth/authenticated']
      }
    }
  },
  methods: {
    login () {
      this.$store.dispatch('auth/forceLogin')
    },
  }
}
</script>
