<template>
  <div id="app">
    <b-navbar type="dark" variant="primary" toggleable="sm" id="main-navbar">
      <b-navbar-brand to="/"><b-img src="logo-inverted.svg" height="30px" class="p-1"/></b-navbar-brand>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-nav v-if="isLoggedIn">
        <b-nav-item to="user-account" :active="isProfileActive">Profile</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="showApps">
        <b-nav-item to="apps" :active="isAppsActive">My Apps</b-nav-item>
      </b-navbar-nav>
      <b-collapse is-nav id="nav_collapse">
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <profile-menu v-if="isLoggedIn"></profile-menu>
            <b-button v-else @click="login" variant="outline-light">
              Log In
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <keep-alive>
      <router-view/>
    </keep-alive>
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<script>
import ProfileMenu from '@/components/ProfileMenu.vue'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  name: 'SafearthIdPortal',
  components: {
    ProfileMenu
  },
  data: function () {
    return {
      'PRODUCT_NAME': process.env.VUE_APP_PRODUCT_NAME
    }
  },
  computed: {
    isLoggedIn: {
      get () {
        return this.$store.getters['auth/authenticated']
      }
    },
    showApps: {
      get () {
        if (this.$store.state.users.currentUser) {
          const accessToken = VueJwtDecode.decode(this.$store.state.auth.accessToken);
          return (
            'https://my.safearth.com/appsUser' in accessToken && 
            accessToken['https://my.safearth.com/appsUser'] == 'true');
        }

        return false;
      }
    },
    isAppsActive: {
      get() {
        return this.$route.name === 'apps' 
      }
    },
    isProfileActive: {
      get() {
        return this.$route.name === 'user-account'
      }
    }
  },
  methods: {
    login () {
      // console.log("Login from app.vue")
      this.$store.dispatch('auth/forceLogin')
    }
  }
}
</script>
