<template>
  <b-dropdown id="profileMenu" right variant="primary">
    <template slot="button-content">
      <font-awesome-icon icon="user-circle" class="fa-lg" />
      <span class="sr-only">User Menu</span>
    </template>

    <b-dropdown-header>Logged in as:<br />{{ this.profileEmail }}</b-dropdown-header>
    <b-dropdown-divider />
    <b-dropdown-item-btn @click="logout">Logout</b-dropdown-item-btn>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ProfileMenu',
  computed: {
    profileName: function () {
      return this.$store.state.auth.user.name
    },
    profileEmail: function () {
      return this.$store.state.auth.user.email
    }
  },
  methods: {
    logout: function () {
      // console.log('logout clicked...')
      this.$store.dispatch('auth/logout')
      this.$router.push('/')
    }
  }
}
</script>

<style>

</style>
