import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import VueResource from 'vue-resource'

// Icon stuff (should try and move it somewhere else eventually)
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserCircle, faFolderOpen, faFileDownload, faSpinner, faRedo, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { faEdit, faWindowClose, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

import '@/scss/custom-styling.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Some icons that might be useful - see here to find others  https://fontawesome.com/icons?d=gallery&m=free
library.add(faUserCircle)
library.add(faFolderOpen)
library.add(faFileDownload, faFileUpload)
library.add(faSpinner)
library.add(faRedo)
library.add(faEdit, faWindowClose, faCheck, faCheckCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(VueResource)
Vue.use(Vuex)

Vue.config.productionTip = false

//Some useful display filters. Should move these to their own file
Vue.filter('exponential', function (value, numDecimalPlaces) {
  if (!value) return '0'
  var places = 3
  if (numDecimalPlaces != null) { places = numDecimalPlaces }
  return value.toExponential(places)
})

Vue.filter('sciFrac', function (value, numDecimalPlaces) {
  if (!value) return '0'
  var places = 3
  if (numDecimalPlaces != null) { places = numDecimalPlaces }
  return value.toExponential(places).split('e')[0]
})

// Note: If you have specified decimal places for the sciFrac
// and you don't specify for sciExp, something like 9.998899e-5
// will get the incorrect exponential (5 instead of 6)
Vue.filter('sciExp', function (value, numDecimalPlaces) {
  if (!value) return null
  return value.toExponential(numDecimalPlaces).split('e')[1]
})

Vue.filter('maxDP', function (value, numDecimalPlaces) {
  if (!value) return '0'
  var places = 3
  if (numDecimalPlaces != null) { places = numDecimalPlaces }
  return Number.parseFloat(value.toFixed(places)).toPrecision()
})

Vue.filter('max2dp', function (value) {
  if (!value) return '0'
  return Number.parseFloat(value.toFixed(2)).toPrecision()
})

Vue.filter('max4dp', function (value) {
  if (!value) return '0'
  return Number.parseFloat(value.toFixed(4)).toPrecision()
})

new Vue({
  router,
  store,
  beforeCreate () {
    // this.$store.commit('auth/getValuesFromLocalStorage')
  },
  render: h => h(App)
}).$mount('#app')
