import axios from 'axios'

const getHttpClient = function (state) {
  const HTTP = axios.create({
    baseURL: process.env.VUE_APP_APPSTREAM_API_URI
  })

  const accessToken = state.accessToken
  if (accessToken) {
    HTTP.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
  }
  // console.log(accessToken)
  // console.log(HTTP.defaults.headers)
  return HTTP
}


const state = {
  applicationList : []
}
const getters = {}
const mutations = {
  setApplicationList (state, list) {
    state.applicationList = list
  },
}
const actions = {
  getAppsList (context, userData) {
    let http = getHttpClient(context.rootState.auth)
      return new Promise((resolve, reject) => {
        http.get('listApps',{userData})
          .then(response => {
            // console.log(response.data)
              resolve(response.data.apps);
          })
          .catch(e => {
              // console.dir(e)
              reject(e);
          })
      });
  },
  openApp (context, url) {
    let http = getHttpClient(context.rootState.auth)
      return new Promise((resolve, reject) => {
        http.get(url)
          .then(response => {
              resolve(response.data);
          })
          .catch(e => {
              // console.dir(e)
              reject(e);
          })
      });
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
