import auth0 from 'auth0-js'
import { isNullOrUndefined } from 'util';
import axios from 'axios'

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_API_URI
})


// ---- Random Helpers etc... ----
const webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENT,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URI,
  responseType: 'token id_token',
  scope: 'openid profile email stream:apps',
  audience: process.env.VUE_APP_AUTH0_API
})

const getFromStorageOrDefault = function(variableName, defaultValue = {}) {
  const x = localStorage.getItem(variableName)
  if (isNullOrUndefined(x)) {
    return defaultValue
  } else {
    return x
  }
}

const loadStateFromLocalStorage = function () {
  let newState = {
    idToken: getFromStorageOrDefault('auth:id_token'),
    accessToken: getFromStorageOrDefault('auth:access_token'),
    expiresAt: getFromStorageOrDefault('auth:expires_at'),
    user: JSON.parse(getFromStorageOrDefault('auth:user', "{}"))
  }

  return newState
}

const saveStateToLocalStorage = function(s) {
  // console.log('Saving Auth state to local storage...')
  // console.dir(s)
  localStorage.setItem('auth:id_token', s.idToken)
  localStorage.setItem('auth:access_token', s.accessToken)
  localStorage.setItem('auth:expires_at', s.expiresAt)
  localStorage.setItem('auth:user', JSON.stringify(s.user))
}

const removeLocalStorage = function() {
  localStorage.removeItem('auth:id_token')
  localStorage.removeItem('auth:access_token')
  localStorage.removeItem('auth:expires_at')
  localStorage.removeItem('auth:user')  
}

const convertExpiry = function(expiresIn) {
  //NOTE: this is a pretty dodgy hack since the closk has been counting 
  //      down ever since the token was issued...
  let expiresAt = expiresIn * 1000 + new Date().getTime()
  return JSON.stringify(expiresAt)
}
// --- VueX stuff


const state = loadStateFromLocalStorage()

const getters = {
  authenticated: state => {
    var nowTime = new Date().getTime()
    return nowTime < state.expiresAt
  },
  //TODO: do we want grace periods with Safearth ID???
  // gracePeriod: state => {
  //   if (state.user) {
  //     //TODO: FIXME....
  //     return state.user['https://aeid.apps/grace_period'] === true
  //   }
  //   return false
  // },
  // graceUntil: state => {
  //   if (state.user) {
  //     //TODO: FIXME...
  //     return state.user['https://aeid.apps/grace_until']
  //   }
  //   return null
  // }
}

const mutations = {
  setIdToken (state, token) {
    state.idToken = token
    saveStateToLocalStorage(state)
  },
  setAccessToken (state, token) {
    state.accessToken = token
    saveStateToLocalStorage(state)
  },
  setExpiresAt (state, expiry) {
    state.expiresAt = expiry
    saveStateToLocalStorage(state)
  },
  setUser (state, user) {
    state.user = user
    saveStateToLocalStorage(state)
  },
  clearAll (state) {
    state.idToken = {}
    state.accessToken = {}
    state.expiresAt = {}
    state.user = {}
    removeLocalStorage()
  }
}

const actions = {
  login () {
    //NOTE: this call will redirect the browser...
    webAuth.authorize({prompt: 'none'}) //NOTE: testing to see if "prompt: none" fixes the 'not your account loop' problem
  },
  forceLogin () {
    //NOTE: this call will redirect the browser...
    webAuth.authorize({prompt: 'login'})
  },
  logout (context) {
    context.commit('clearAll')
    //NOTE: this call will redirect the browser...
    webAuth.logout({
      returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URI
    })
  },
  // Not sure why this is needed.. appears to be a duplicate of authenticated property
  // isAuthenticated (context) {
  //   return context.getters.authenticated
  // },
  handleAuthentication (context) {
    return new Promise((resolve, reject) => {
      webAuth.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          context.commit('setIdToken', authResult.idToken) //TODO: we possibly should store the token payload...
          context.commit('setAccessToken', authResult.accessToken)
          context.commit('setUser', authResult.idTokenPayload)
          context.commit('setExpiresAt', convertExpiry(authResult.expiresIn))
          // console.log('Looks like we successfully parsed an auth hash')
          // console.dir(authResult)
          return resolve(authResult.idTokenPayload)
        } else if (err) {
          // console.log('There was an error logging in...')
          // console.dir(err)
          // console.log('Clearing all saved auth state')
          context.commit('clearAll')
          return reject(err)
        } 
      })
    })
  },
  getManagementToken () {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({
        audience: 'https://' + process.env.VUE_APP_AUTH0_DOMAIN + '/api/v2/',
        scope: 'read:current_user update:current_user_identities'
      }, (err, authResult) => {
          if (err)
          {
            // console.log('getManagementToken reject')
            reject(err)
          }
          // console.log('getManagementToken resolve')
          resolve(authResult)
      })
  })
  },
  getUserManagementToken () {
    return new Promise((resolve, reject) => {
      webAuth.checkSession({
        audience: 'https://my.safearth.com/v2',
        scope: ''
      }, (err, authResult) => {
          if (err)
          {
            // console.log('getManagementToken reject')
            reject(err)
          }
          // console.log('getManagementToken resolve')
          resolve(authResult)
      })
  })
  },
  async getCurrentUserData ({dispatch}) {
    // Get a new access token for the management api...

    let response = await dispatch('getManagementToken')
    // console.log(response)
    // console.log(response.accessToken)
    let client = new auth0.Management({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      token: response.accessToken
    })
    return new Promise((resolve, reject) => {
    client.getUser(response.idTokenPayload.sub,
      (err, authResult) => {
        if (err)
        {
          // console.log('getUser reject')
          reject(err)
        }
        // console.log('getUser resolve')
        // console.log(authResult)
        resolve(authResult)
      })
    })
  },
  async updateCurrentUserName ({dispatch}, userName) {
    // Get a new access token for the management api...

    let response = await dispatch('getUserManagementToken')
    // console.log(response)
    // console.log(response.accessToken)
    let client = new auth0.Management({
      domain: process.env.VUE_APP_AUTH0_DOMAIN,
      token: response.accessToken
    })

    console.log(client)

    return new Promise((resolve, reject) => {
      HTTP.post('updateUser', userName, { headers:{'Authorization':'Bearer ' + client.baseOptions.token }})
        .then(response => {
          console.log('updateUser response')
          resolve(response)
        })
        .catch(e => {
          console.log('updateUser error response')
          console.log(e.response)
          reject(e)
        })
    })

    // return new Promise((resolve, reject) => {
    // client.patchUserAttributes(response.idTokenPayload.sub, {name:userName},
    //   (err, authResult) => {
    //     if (err)
    //     {
    //       // console.log('updateUser reject')
    //       reject(err)
    //     }
    //     // console.log('updateUser resolve')
    //     // console.log(authResult)
    //     resolve(authResult)
    //   })
    // })
  }



    // await dispatch('getManagementToken')
    //   .then(response => {
    //     console.log('getManagementToken response')
    //     console.log(response)
    //     client = new auth0.ManagementClient({
    //       domain: process.env.VUE_APP_AUTH0_DOMAIN,
    //       token: response.access_token
    //     })
    //     console.log('Management client created')
    //     tokenResponse= response
    //   }).catch(err => {
    //       console.log('problem getting token for management API:')
    //       console.dir(err)
    //       return Promise.reject(err)
    //   })
      
    
  
}



export default {
  namespaced: true,
  modules: {
  },
  state,
  getters,
  actions,
  mutations
}
