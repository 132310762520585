import Vue from 'vue'
import Vuex from 'vuex'
import users from './modules/users'
import auth from './modules/auth'
import appList from './modules/appList'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const state = {}
const actions = {}
const getters = {}
const mutations = {}

export default new Vuex.Store({
  modules: {
      users,
      auth, 
      appList
  },
  actions,
  state,
  getters,
  mutations,
  strict: debug
})
