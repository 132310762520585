const DEFAULT_TITLE = 'mySafearth';

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
const UserAccountManagement = () => import(/* webpackChunkName: "profile" */  './views/UserAccountManagement.vue')
const SignUp = () => import(/* webpackChunkName: "user" */ './views/AddNewUser.vue')
const SignupSuccessful = () => import(/* webpackChunkName: "user" */ './views/SignupSuccessful.vue')
const ResendEmail = () => import(/* webpackChunkName: "user" */ './views/ResendEmail')
const ResetPassword = () => import(/* webpackChunkName: "user" */ './views/ResetPassword')
const AuthCallback = () => import(/* webpackChunkName: "auth" */ '@/views/AuthCallback.vue')
const AuthError = () => import(/* webpackChunkName: "auth" */  '@/views/AuthError.vue')
const AppList = () => import(/* webpackChunkName: "appstream" */  '@/views/AppList.vue')
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { title: 'Home' },
      component: Home
    },
    {
      path: '/user-account',
      name: 'user-account',
      meta: { title: 'User Account' },
      component: UserAccountManagement
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      meta: { title: 'Sign Up' },
      component: SignUp
    },
    {
      path: '/verify',
      name: 'verify',
      meta: { title: 'Verify Account' },
      component: SignupSuccessful
    },
    {
      path: '/resend-email',
      name: 'resend-email',
      meta: { title: 'Re-send Verification Email' },
      component: ResendEmail
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: { title: 'Reset Password' },
      component: ResetPassword
    },
    {
      path: '/auth-callback',
      name: 'auth-callback',
      component: AuthCallback
    },
    {
      path: '/auth-error',
      name: 'auth-error',
      props: true,
      component: AuthError
    },
    {
      path: '/apps',
      name: 'apps',
      meta: { title: 'My Apps' },
      props: true,
      component: AppList
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  // Allow access to the "authentication callback"
  if (to.name === 'auth-callback' || to.name === 'auth-error' || to.name === 'sign-up') {
    next()
  }
  else if (to.name === 'resend-email' || to.name === 'reset-password') {
    next()
  }
  else if (store.getters['auth/authenticated']) {
    if (to.name != 'user-account' && to.name != 'apps') {
      // if you're authenticated, go to account management page
      next('/user-account')
    }
    else {
      next()
    }
  }
  else if (to.name === 'verify' && from.name === 'sign-up') {
    next()
  }
  else {
    // we're going to force you to log in...
    if (to.path === '/') {
      next()
    } else {
      next('/')
    }
  }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta.title)
      document.title = `${to.meta.title} - ${DEFAULT_TITLE}`;
    else
      document.title = DEFAULT_TITLE;
  });
});

export default router
